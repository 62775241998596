import gql from 'graphql-tag'


const detailResponse = `
  id type code name isActive
  categories {id name}
  children {
    id type code name order parentId
    children {
      id type code name order parentId
      description formType
      preValue minValue maxValue
      choices {text value}
      cron
      inventoryId inventory {
        id type code name
        unitId unit {id name}
      }
      deviceId device {
        id type code name
      }
    }
  }
  masterId master {
    id type code name
    children {
      id type code name order parentId
      children {
        id type code name order parentId
        description formType
        preValue minValue maxValue
        choices {text value}
        cron
        inventoryId inventory {
          id type code name
          unitId unit {id name}
        }
        deviceId device {
          id type code name
        }
      }
    }
  }
`

export const LIST_PROCEDURE_CONTROL = (templateType) => gql`query LIST_PROCEDURE_CONTROL ($procedureType: String!, $q: FilterInput) {
  procedureControls: list${templateType} (procedureType: $procedureType, q: $q) {
    id type code name isActive
  }
}`

export const DETAIL_PROCEDURE_CONTROL = (templateType) => gql`query DETAIL_PROCEDURE_CONTROL ($procedureType: String!, $procedureControlId: Int!) {
  procedureControl: detail${templateType} (procedureType: $procedureType, procedureControlId: $procedureControlId) {${detailResponse}}
}`

export const CREATE_PROCEDURE_CONTROL = (templateType) => gql`mutation CREATE_PROCEDURE_CONTROL ($procedureType: String!, $input: ${templateType}Input!) {
  createProcedureControl: create${templateType} (procedureType: $procedureType, input: $input) {${detailResponse}}
}`

export const UPDATE_PROCEDURE_CONTROL = (templateType) => gql`mutation UPDATE_PROCEDURE_CONTROL ($procedureType: String!, $procedureControlId: Int!, $input: ${templateType}Input!) {
  updateProcedureControl: update${templateType} (procedureType: $procedureType, procedureControlId: $procedureControlId, input: $input) {${detailResponse}}
}`

export const DESTROY_PROCEDURE_CONTROL = (templateType) => gql`mutation DESTROY_PROCEDURE_CONTROL ($procedureType: String!, $procedureControlId: Int!) {
  destroyProcedureControl: destroy${templateType} (procedureType: $procedureType, procedureControlId: $procedureControlId) {id}
}`
